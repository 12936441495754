import { useEffect, useState } from 'react';
function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        function handleResize() {
            // detect device screen size, depending on orientation
            let screenHeight = 0;
            if (window.navigator && !!window.navigator.standalone) {
                if (window.orientation && (window.orientation === 90 || window.orientation === -90)) {
                    screenHeight = window.screen.width;
                } else {
                    screenHeight = window.screen.height;
                }
            }
            // save
            setWindowSize({
                width: window.innerWidth,
                height: 'standalone' in window.navigator && !!window.navigator.standalone ? screenHeight : window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}
export default useWindowSize;