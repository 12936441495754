import {useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {useSwipeable} from 'react-swipeable';
// import Spinner from 'spinner';
import Spinner from '../reel/src/js/spinner';
import {SocketContext} from '../context/socket';
import {motion} from 'framer-motion';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useAtom} from 'jotai';
import {
  currentGameStatus,
  currentSpinResultState,
  currentSpinState,
  firstSpinState,
  gameCompletedState,
  generateGameState,
  joinedRoomState,
  lobbyFullState,
  lobbyIdState,
  lobbySizeState,
  modalOpenState,
  newGameStatusState,
  screenClassState,
  personalisationDetailsState,
  playerNumberState,
  userDisconnectionState,
  userLeaveState,
  hostState
} from '../state/default_state';
import {makeid} from '../App';

let firstLoadTimeoutTriggered = false;

export const Game = () => {
  const socket = useContext(SocketContext);
  const [isSpinning, setIsSpinning] = useState(false);
  const [lastSpin, setLastSpin] = useState(false);

  let {lobbynumber, sharerid} = useParams();

  const [lobbySize] = useAtom(lobbySizeState);
  const [lobbyFull] = useAtom(lobbyFullState);
  const [firstSpin, setFirstSpin] = useAtom(firstSpinState);
  const [currentSpinResult, setCurrentSpinResult] = useAtom(currentSpinResultState);
  const [userDisconnection, setUserDiconnection] = useAtom(userDisconnectionState);
  const [, setUserLeave] = useAtom(userLeaveState);

  const [playerNumber] = useAtom(playerNumberState);
  const [currentSpin, setCurrentSpin] = useAtom(currentSpinState);
  const [gameStatus, setGameStatus] = useAtom(currentGameStatus);

  const navigate = useNavigate();
  const location = useLocation();
  const [, setuserGenerateGame] = useAtom(generateGameState);
  const [personalisationDetails, setPersonalisationDetails] = useAtom(personalisationDetailsState);
  const [lobbyId, setLobbyId] = useAtom(lobbyIdState);
  const [hasJoinedRoom] = useAtom(joinedRoomState);
  const [gameCompleted, setGameCompleted] = useAtom(gameCompletedState);
  const [modalOpen, setModalOpen] = useAtom(modalOpenState);
  const [, setNewGameStatus] = useAtom(newGameStatusState);
  const [screenClass, setScreenClass] = useAtom(screenClassState);
  const [isHost] = useAtom(hostState);

  const r = useRef();
  const lastSwipeListenerEnabled = useRef(false);

  const foo = () => {
    if (lastSpin === false && r.current && isSpinning === false && currentSpin === 13) {
      setLastSpin(true);
      socket.emit('request_last_game', lobbyId);
      setTimeout(() => {
        setGameCompleted(true);
      }, 16000);
    }
  };

  const handlers = useSwipeable({
    onSwipedDown: eventData => foo(),
    preventScrollOnSwipe: true
  });

  const navVariants = {
    open: {y: 200},
    closed: {y: 0}
  };
  const modalVariants = {
    open: {opacity: 0, display: 'none'},
    closed: {opacity: 1, display: 'flex'}
  };

  const dropIn = {
    closed: {
      y: '100vh',
      opacity: 0
    },
    open: {
      y: '0',
      opacity: 1
    },
    exit: {
      y: '-100vh',
      opacity: 0
    }
  };

  useEffect(() => {
    if (!firstLoadTimeoutTriggered) {
      setTimeout(() => {
        socket.emit('set_game_ready', lobbyId);
      }, 3000);
    }
  }, [isHost, lobbyId, socket]);

  useEffect(() => {
    socket.on('game_ready', function () {
      setScreenClass('screen_light screen_light__disable-pointer');
      firstLoadTimeoutTriggered = true;
    });
  });

  //Create or Join Game
  useEffect(() => {
    setUserDiconnection(false);
    if (lobbynumber) {
      setLobbyId(lobbynumber);
    }
    if (location.state && location.state.userGenerated === true) {
      setuserGenerateGame(true);
    }

    if (!hasJoinedRoom) {
      socket.emit('create_or_join', lobbyId);
    }
  }, [hasJoinedRoom, lobbyId, lobbynumber, location.state, setLobbyId, setUserDiconnection, setuserGenerateGame, socket]);

  //Spin
  useEffect(() => {
    const onReelSpinEvent = evt => {
      var params = new URLSearchParams();
      params.append('identity', lobbyId);
      setIsSpinning(true);
      if (personalisationDetails && personalisationDetails['game_type'] === 'personalised') {
        axios.post('https://christmas.kerve.co.uk/api/v1/game.play', params).then(response => {
          setCurrentSpinResult(response.data.data.prize);
          socket.emit(
            'request_spin',
            lobbyId,
            response.data.data.current_spin === 12 ? 13 : response.data.data.next_spin,
            response.data.data.status,
            response.data.data.prize?.image_id
          );
          socket.emit('send_prize', lobbyId, response.data.data.prize);
        });
      } else {
        socket.emit('request_spin', lobbyId, currentSpin + 1, currentSpin === 13 ? 'completed' : 'free-play');
      }
    };

    window.addEventListener('onReelSpin', onReelSpinEvent);

    return function cleanupListener() {
      window.removeEventListener('onReelSpin', onReelSpinEvent);
    };
  }, [currentSpin, currentSpinResult, lobbyId, personalisationDetails, setCurrentSpinResult, socket]);

  //Get Game Lookup
  useEffect(() => {
    if (!personalisationDetails) {
      if (sharerid) {
        axios(`https://christmas.kerve.co.uk/api/v1/game.lookup?identity=${lobbynumber}&sharer=${sharerid}`).then(response => {
          setPersonalisationDetails(response.data.data);
        });
      } else {
        axios(`https://christmas.kerve.co.uk/api/v1/game.lookup?identity=${lobbynumber}`).then(response => {
          setPersonalisationDetails(response.data.data);
        });
      }
    }
  }, [lobbyId, lobbynumber, personalisationDetails, setPersonalisationDetails, sharerid]);

  useEffect(() => {
    const getColour = () => {
      if (playerNumber === 1) {
        return 'red';
      } else if (playerNumber === 2) {
        return 'yellow';
      } else {
        return 'aqua';
      }
    };

    const getImagesForReel = () => {
      const colour = getColour();
      const imageArray = [];
      for (let i = 1; i < 15; i++) {
        if (i === 8) {
          if (personalisationDetails && personalisationDetails.game_type === 'free_play') {
            imageArray.push(`/img/brand/brand_default.png`);
          } else {
            imageArray.push(`/img/brand/brand_${lobbyId}.png`);
          }
        }
        if (i < 10) {
          imageArray.push(`/img/${colour}_trees/${colour}_reel_tree0${i}.png`);
        } else {
          imageArray.push(`/img/${colour}_trees/${colour}_reel_tree${i}.png`);
        }
      }
      imageArray.unshift(`/img/goodluck.png`);
      return imageArray;
    };

    const getFinalImage = () => {
      if (personalisationDetails && personalisationDetails.game_type === 'free_play') {
        if (playerNumber === 1) {
          return '/img/messaging/4_thanks_from_kerve.png';
        } else if (playerNumber === 2) {
          return '/img/messaging/5_amazing_christmas.png';
        } else {
          return '/img/messaging/6_see_you_2023.png';
        }
      } else {
        if (playerNumber === 1) {
          return `/img/thanks/${lobbyId}.png`;
        } else if (playerNumber === 2) {
          return '/img/messaging/2_amazing_christmas.png';
        } else {
          return '/img/messaging/3_look_forward.png';
        }
      }
    };

    const setBgColour = () => {
      if (playerNumber === 1) {
        return '#C3494F';
      } else if (playerNumber === 2) {
        return '#f6c87e';
      } else if (playerNumber === 3) {
        return '#016E75';
      }
    };

    const config = {
      color: setBgColour(),
      images: getImagesForReel(),
      last: getFinalImage(),
      reflect: '/img/env.jpg',
      model: '/3d/reel.gltf',
      min_speed: 3, // radians per second
      max_speed: 6 // (1 rad === Math.PI * 2)
    };

    if (!r.current && document.querySelector('#reel_1')) {
      r.current = new Spinner(document.querySelector('#reel_1'), config, true);
    }
  }, [isSpinning, lobbyId, personalisationDetails, playerNumber, socket]);

  useEffect(() => {
    const config = {
      min_speed: 3, // radians per second
      max_speed: 4 // (1 rad === Math.PI * 2)
    };

    socket.on('last_spin', function () {
      console.log(lastSpin);
      if (lastSpin === false && r.current && isSpinning === false) {
        setLastSpin(true);
        r.current.lastStop(0);
        setTimeout(() => {
          setGameCompleted(true);
        }, 16000);
      }
    });

    socket.on('spin', function (spinVal, spinCount, status, result) {
      setGameStatus(status);

      if (isSpinning === false && r.current) {
        setIsSpinning(true);
        r.current.spin(config.min_speed, playerNumber === 2 ? 250 : 500);
      }
      if (firstSpin) {
        setFirstSpin(false);
      }

      console.log(spinCount);
      if (spinCount === 13) {
        setScreenClass('screen_light');
      }

      setTimeout(() => {
        setCurrentSpin(spinCount);
        if (r.current) {
          if (status === 'completed') {
            r.current.lastStop(0);
            setTimeout(() => {
              setGameCompleted(true);
            }, 18000);
          } else {
            r.current.stop(spinVal);
          }
        }
        setIsSpinning(false);
      }, 4000);

      if (status === 'win') {
        setTimeout(() => {
          setModalOpen(true);
        }, 5000);
      }
    });
  }, [
    firstSpin,
    isSpinning,
    lastSpin,
    lobbyId,
    playerNumber,
    setCurrentSpin,
    setFirstSpin,
    setGameCompleted,
    setGameStatus,
    setModalOpen,
    setScreenClass,
    socket
  ]);

  useEffect(() => {
    socket.on('close_modal', function () {
      setModalOpen(false);
    });
  }, [setModalOpen, socket]);

  useEffect(() => {
    socket.on('prize', function (prize) {
      setCurrentSpinResult(prize);
    });
  }, [setCurrentSpinResult, socket]);

  const handleCloseModal = () => {
    socket.emit('request_modal_close', lobbyId);
    setModalOpen(false);
  };

  const handleNewGame = () => {
    const newID = makeid(6);
    navigate(`/i/${newID}/${lobbyId}`, {state: {userGenerated: true, setAsHost: true}});
    socket.emit('create_or_join', newID);
    setCurrentSpin(0);
    setUserDiconnection(false);
    setUserLeave(false);
    setNewGameStatus(true);
    setGameStatus('free-play');
    setGameCompleted(false);
    setModalOpen(false);
    setLastSpin(false);
    setFirstSpin(false);
    setScreenClass('screen_light');
    r.current = null;
    lastSwipeListenerEnabled.current = null;
  };

  if (window.innerHeight < window.innerWidth) {
    return (
      <motion.div className="app justify-center" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 1, delay: 0.5}}>
        <p className="app__instructions">Sorry, please turn your screen to portrait to play.</p>
      </motion.div>
    );
  }

  if (gameCompleted) {
    socket.emit('leave', lobbyId);
    return (
      <motion.div className="app justify-center" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 1, delay: 0.5}}>
        <div className="app__completed">
          <p className="app__instructions">You can still spin for fun and feel free to share a no-prize version of Kerve’s whizz-kiddery.</p>
          <span
            className="app__start-game link"
            onClick={() => {
              navigator.clipboard.writeText(`${window.location.origin}/i/?lobby=${lobbyId}`);
              setModalOpen(true);
            }}
          >
            <span>copy share link</span>
            <img alt="link" src="/link.svg"></img>
          </span>
          <span className="app__start-game play-again" onClick={() => handleNewGame()}>
            play again
          </span>
          <p onClick={() => navigate('https://kerve.co.uk')} className="completed-link kerve">
            kerve.co.uk
          </p>
        </div>
        <motion.div
          className="modal"
          animate={modalOpen ? 'open' : 'closed'}
          variants={dropIn}
          initial={{opacity: 0}}
          transition={{duration: 2, type: 'spring'}}
          exit="exit"
        >
          <div className="modal__outer">
            <div className="modal__inner">
              <p className="modal__heading" style={{whiteSpace: 'pre-line'}}>
                Your share link has been copied
              </p>
              <span className="modal__button" onClick={() => handleCloseModal()}>
                close
              </span>

              {/* {currentSpinResult && currentSpinResult.code && <p className="modal__id">{currentSpinResult.code}</p>} */}
            </div>
          </div>
        </motion.div>
      </motion.div>
    );
  }

  if (userDisconnection || lobbySize < 3 || lobbyFull) {
    setTimeout(() => {
      navigate(`/i/${lobbyId}`);
    }, 1000);
  }

  if (lobbySize === 3 && !lobbyFull && !userDisconnection) {
    return (
      <motion.div className="app" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 1, delay: 0.5}}>
        <canvas id="reel_1"></canvas>
        <img {...handlers} alt="screen" className={screenClass} src="/img/reel_light.png"></img>
        <div className="overlay-wrapper">
          <motion.div
            id="overlay"
            animate={gameStatus !== 'completed' && !isSpinning && !lastSpin && !modalOpen ? 'closed' : 'open'}
            variants={navVariants}
            transition={{duration: 1, delay: 0.75}}
            initial={{y: 0}}
          >
            <p className="spin-text-1">
              {currentSpin === 13
                ? 'GO ON THEN...'
                : currentSpin === 1 || (firstSpin && currentSpin <= 1)
                ? "WHO'S UP FIRST?"
                : `${13 - currentSpin} SPIN${currentSpin !== 12 ? 'S' : ''} LEFT`}
            </p>
            <p className="spin-text-2">{currentSpin === 13 ? 'Have a free spin!' : currentSpin === 0 || firstSpin ? 'Swipe down to spin' : 'Spin again'}</p>
          </motion.div>
        </div>

        <motion.div
          className="modal"
          animate={modalOpen && !isSpinning ? 'open' : 'closed'}
          variants={dropIn}
          initial={{opacity: 0}}
          transition={{duration: 2, type: 'spring'}}
          exit="exit"
        >
          <div className="modal__outer">
            <div className="modal__inner">
              <p className="modal__heading" style={{whiteSpace: 'pre-line'}}>
                {personalisationDetails && personalisationDetails.game_type === 'free_play'
                  ? 'Congratulations,\n3 in a row!'
                  : "Congratulations,\n it's a winner"}
              </p>
              <p className="modal__body">
                {personalisationDetails && personalisationDetails.game_type === 'free_play'
                  ? 'But sorry, all Kerve’s Christmas prizes have now gone. Better luck next year.'
                  : [
                      `${personalisationDetails && personalisationDetails['first_name']}'s won`,
                      currentSpinResult && currentSpinResult.title?.replace(/(\r\n|\n|\r)/gm, ' '),
                      'It’s in the sleigh and on its way.'
                    ].join(' ')}
              </p>
              <span className="modal__button" onClick={() => handleCloseModal()}>
                continue
              </span>

              {/* {currentSpinResult && currentSpinResult.code && <p className="modal__id">{currentSpinResult.code}</p>} */}
            </div>
          </div>
        </motion.div>
      </motion.div>
    );
  }
};
