import {atom} from 'jotai';

export const joinedRoomState = atom(false);

export const hostState = atom(false);

export const lobbySizeState = atom(0);

export const lobbyFullState = atom(false);

export const firstSpinState = atom(true);

export const currentSpinResultState = atom(undefined);

export const generateGameState = atom(false);

export const userDisconnectionState = atom(false);

export const userLeaveState = atom(false);

export const userGeneratedURLstate = atom('');

export const personalisationDetailsState = atom(undefined);

export const lobbyIdState = atom('');

export const playerNumberState = atom(0);

export const currentSpinState = atom(0);

export const currentGameStatus = atom('in-progress');
export const newGameStatusState = atom(false);

export const gameCompletedState = atom(false);
export const modalOpenState = atom(false);

export const screenClassState = atom('screen_light');
