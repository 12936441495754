/**
 * Fruit Machine Spinner
 *
 * @author Mike Cobb
 * @copy   Highly Interactive Ltd
 * @date   28/11/2022
 **/

import ThreeD from './threeD';
import Model from './model';
import Reel from './reel';

export default class Spinner {
  #animID = -1;
  #wide;
  #high;
  #images;
  #minSpeed;
  #maxSpeed;
  #canvas;
  #world;
  #model;
  #reel;
  #dragging = false;
  #spinning = false;
  #stopping = false;
  #speed = 0;
  #delta = 0;
  #last = 0;
  #stats;

  #SPIN_THRESHOLD = 0.05;

  constructor(canvas, config, debug = false) {
    this.#canvas = canvas;

    //Config
    this.#images = this.shuffleImages(config.images);
    this.#minSpeed = config.min_speed !== undefined ? config.min_speed : 3;
    this.#maxSpeed = config.max_speed !== undefined ? config.max_speed : 6;

    if (this.#images === null) return;

    this.#wide = document.documentElement.clientWidth;
    this.#high = document.documentElement.clientHeight;

    //Create ThreeJS
    this.#world = new ThreeD(this.#canvas, config.reflect, this.#wide, this.#high);

    //Configure reel model
    this.#model = new Model(config, this.#world);

    //Load the textures
    this.#model.load(this.#images).then(mesh => {
      this.#reel = new Reel(this.#world, mesh, this.#images);

      this.isReady = true;
      this.resize();
    });

    this.bindEvents();
    this.render();
  }

  get speed() {
    return this.#speed;
  }

  bindEvents() {
    this.#canvas.addEventListener('mousedown', evt => this.onDown(evt), false);
    this.#canvas.addEventListener('touchstart', evt => this.onDown(evt), false);
    this.#canvas.addEventListener('mousemove', evt => this.onMove(evt), false);
    this.#canvas.addEventListener('touchmove', evt => this.onMove(evt), false);
    this.#canvas.addEventListener('touchend', evt => this.onRelease(evt), false);

    document.addEventListener('mouseup', evt => this.onRelease(evt), false);

    window.addEventListener('orientationchange', () => this.resize());
    window.addEventListener('resize', () => this.resize());
  }

  spin(speed, offset) {
    //kill snapback tweens (temp fix for multi-reel)
    this.#reel.clearTweens();

    setTimeout(() => {
      this.#speed = speed;
      this.#spinning = true;
    }, offset);
  }

  destroy() {
    this.destroy();
  }

  stop(target, offset) {
    if (this.#speed === 0) return;

    this.#stopping = true;
    this.#spinning = false;
    this.#dragging = false;

    this.#reel.stop(target, this.#speed, offset / 1000, () => {
      this.#stopping = false;
      this.#speed = 0;
      this.#delta = 0;
      this.#last = 0;
    });
  }

  lastStop(offset) {
    console.log('this is the last stop');
    console.log(offset);
    console.log(this.#speed);
    this.#speed = 0.5;
    if (this.#speed === 0) return;
    console.log('this is the last stop after return');

    this.#stopping = true;
    this.#spinning = false;
    this.#dragging = false;

    this.#reel.stop(0, this.#speed, offset / 1000, () => {
      console.log('this is the last stop after return stop called');
      this.#model.flipEnd();

      this.#stopping = false;
      this.#speed = 0;
      this.#delta = 0;
      this.#last = 0;
    });
  }

  resize() {
    this.#wide = document.documentElement.clientWidth;
    this.#high = document.documentElement.clientHeight;

    this.#world.resize(this.#wide, this.#high);
  }

  render() {
    this.#animID = requestAnimationFrame(() => this.render());

    if (!this.isReady) return;

    if (this.#dragging) this.#reel.drag();
    else if (this.#spinning) this.#reel.spin(this.#speed);
    else if (this.#stopping) this.#reel.slow();

    let blur = this.#reel.getSpeed();
    blur = Math.ceil(blur * 500) / 500.0;
    this.#model.update(blur);

    this.#world.render();

    if (this.#stats) this.#stats.update();
  }

  shuffleImages(arr) {
    if (arr.length !== 16) {
      console.error('ERROR: Incorrect number of images in config');
      return null;
    }

    //Create some random positions
    let ids = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    ids = this.shuffle(ids);

    const images = [];

    //Create lookup of positions
    for (let i = 0; i < arr.length; i++) {
      if (i === 0) images[0] = {id: 0, pos: 0, uri: arr[0]}; //always 0
      else images[i] = {id: i, pos: ids[i - 1], uri: arr[i]};
    }

    return images;
  }

  shuffle(array) {
    let i = array.length,
      ri;

    while (i != 0) {
      ri = Math.floor(Math.random() * i);
      i--;

      [array[i], array[ri]] = [array[ri], array[i]];
    }

    return array;
  }

  onDown(evt) {
    evt.preventDefault();

    if (!this.isReady) return;
    if (this.#spinning) return;

    this.#speed = 0;

    this.#last = evt.touches === undefined ? evt.clientY : evt.touches[0].clientY;
    this.#delta = this.#last;
    this.#dragging = true;
  }

  onMove(evt) {
    evt.preventDefault();

    if (!this.isReady) return;
    if (this.#spinning) return;

    //Get cursor/touch position
    const py = evt.touches === undefined ? evt.clientY : evt.touches[0].clientY;

    if (this.#dragging) {
      this.#delta = py - this.#last;
      this.#last = py;

      //Dodgy maths to drag cylinder
      this.#speed = (this.#delta / this.#high) * Math.PI * this.#world.vFov;
      // this.#speed *= 60;
      this.#reel.addSpeed(this.#speed);
    }
  }

  onRelease(evt) {
    evt.preventDefault();

    this.#dragging = false;

    if (this.#spinning) return;

    //Are we spinning?
    if (this.#speed > this.#SPIN_THRESHOLD) {
      this.#speed *= 60;

      //Clamp to max speed
      if (this.#speed > this.#maxSpeed) this.#speed = this.#maxSpeed;

      window.dispatchEvent(new CustomEvent('onReelSpin', {detail: {speed: this.#speed}}));

      this.#spinning = true;
    } else {
      //Snap back
      this.#reel.snap();
    }
  }
}
