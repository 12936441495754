export default `

precision highp float;

#ifndef SAMPLES
  #define SAMPLES 10
#endif

#ifndef LOW_SAMPLES
  #define LOW_SAMPLES 3
#endif

uniform sampler2D texture;
uniform float blur;

varying vec2 vUv;

vec2 fixOffset (in vec2 _uv)
{
	_uv.x += floor(_uv.y) * 0.25;
	_uv.x = mod(_uv.x, 1.0);

	_uv.y = mod(_uv.y, 1.0);

	return _uv;
}

void main ()
{
	vec2 uv = vUv;

	vec4 result = texture2D(texture, uv);

	for (int i = 1; i < SAMPLES; ++i)
	{
		// get offset in range [-0.5, 0.5]:
		float offset = blur * (float(i) / float(SAMPLES - 1) - 0.5);

		// sample & add to result:
		result += texture2D(texture, fixOffset(uv + vec2(0.0, offset)));
	}

	result /= float(SAMPLES);


	gl_FragColor = result;
}


`;