import * as THREE from "three";
import { RectAreaLightUniformsLib } from "three/examples/jsm/lights/RectAreaLightUniformsLib.js";
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper.js";

export default class ThreeD
{
	constructor (canvas, e, w, h)
	{
		this.canvas = canvas;

		this.scene = new THREE.Scene();
		this.scene.name = "WWF_Dissolve";

		this.renderer = new THREE.WebGLRenderer({canvas: this.canvas, antialias: false});
		this.renderer.powerPreference = "high-performance";
		this.renderer.setSize(w, h);
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setClearColor(0x000000, 1);

		// this.renderer.toneMapping = THREE.LinearToneMapping;
		// this.renderer.toneMappingExposure = 0.9;

		const fov = 40;
		const ratio = canvas.width / canvas.height;
		const near = 0.1;
		const far = 200;

		this.camera = new THREE.PerspectiveCamera(fov, ratio, near, far);
		this.camera.lookAt(new THREE.Vector3(0, 0, 0));
		this.scene.add(this.camera);
		
/*
		THIS IS A HUGE PERFORMANCE HIT
		
		//Ambient Light
		// this.al = new THREE.AmbientLight(0xffffff, 0.15);
		// this.al = new THREE.AmbientLight(0xffffff, 1.2);
		// this.scene.add(this.al);

		// RectAreaLightUniformsLib.init();

		//Area Light
		this.rl = new THREE.RectAreaLight(0xffffff, 0.5, 20, 40);
		this.rl.position.set(-1, 20, 20);
		this.rl.lookAt(new THREE.Vector3(0, 10, 0));
		this.rl.rotation.z = -0.78;
		this.scene.add(this.rl);

		this.p1 = new THREE.PointLight(0xffffff, 0.75, 100, 2);
		this.p1.position.set(-5, 0, 20);
		this.scene.add(this.p1);

		this.p2 = new THREE.PointLight(0xffffff, 0.75, 100, 2);
		this.p2.position.set(5, 0, 20);
		this.scene.add(this.p2);
*/
		this.scene.rotation.y = Math.PI * 0.5;
	}

	resize (w, h)
	{
		if (w === undefined) w = document.documentElement.clientWidth;
		if (h === undefined) h = document.documentElement.clientHeight;

		this.renderer.setSize(w, h);

		this.camera.aspect = w / h;
		this.camera.updateProjectionMatrix();

		//Calculate vertical field of view (required for reel spin)
		const radAng = THREE.MathUtils.degToRad(this.camera.fov);
		this.vFov = 2 * Math.atan(Math.tan(radAng / 2.0) * this.camera.aspect);

		//Calculate camera position
		if (w / h < 1) this.camera.position.z = this.getPortraitZoom(this.reelSize);
		else this.camera.position.z = this.getLandscapeZoom(this.reelSize);
	}

	getPortraitZoom (size)
	{
		const dist = (size * 0.5) / Math.tan(this.vFov * 0.5);
		return dist + this.reelRadius;
	}

	getLandscapeZoom (size)
	{
		const halfFov = THREE.MathUtils.degToRad(this.camera.fov * 0.5);
		const dist = (size * 0.5) / Math.tan(halfFov);

		return dist + this.reelRadius;
	}

	render ()
	{
		this.renderer.render(this.scene, this.camera);
	}

	destroy ()
	{
		if (this.scene)
		{
			if (this.camera) this.scene.remove(this.camera);
			if (this.al) this.scene.remove(this.al);
			if (this.rl) this.scene.remove(this.rl);
			if (this.p1) this.scene.remove(this.p1);
			if (this.p2) this.scene.remove(this.p2);
		}

		this.renderer = null;
		this.camera = null;
		this.scene = null;
		this.p1 = null;
		this.p2 = null;
		this.rl = null;
		this.al = null;
	}
}