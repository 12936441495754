/**
 * Fruit Machine Model
 *
 * @author Mike Cobb
 * @copy   Highly Interactive Ltd
 * @date   28/11/2022
**/

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
// import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
 
 import shaderVert from "./xmas_blur.vert.glsl";
 import shaderFrag from "./xmas_blur.frag.glsl";
import { TextureLoader } from "three";
 
export default class Model
{
	#material;
	#texture;
	#context;
	#buffer;
	#world;
	#ortho;
	#size = 2048;
	#count;
	#color = "#ff6600";
	#uri = "./3d/reel.gltf";
	#end = "./img/end.png";
	#map = "./img/env.hdr";
	#endImage;
	#lastBlur = -1;
	#stepInterval = 2;
	#step = 0;

	constructor (config, world)
	{
		//Get model uri
		if (config.model !== undefined) this.#uri = config.model;

		//Get end texture uri
		if (config.last !== undefined) this.#end = config.last;

		//Get reflection map uri
		if (config.reflect !== undefined) this.#map = config.reflect;

		//Get reel colour
		if (config.color !== undefined) this.#color = config.color;

		this.#world = world;

		//Create a canvas texture
		const canvas = document.createElement("canvas");
		canvas.width = canvas.height = this.#size = 2048;

		this.#texture = new THREE.CanvasTexture(canvas);
		this.#texture.flipY = false;
		this.#context = canvas.getContext("2d");

		this.#context.fillStyle = this.#color;
		this.#context.fillRect(0, 0, this.#size, this.#size);

		this.#context.fillStyle = "#ffffff";

		//Create a camera rig to film the canvas texture
		this.#ortho = new THREE.OrthographicCamera(this.#size * -0.5, this.#size * 0.5, this.#size * 0.5, this.#size * -0.5, 1, 100);
		this.#ortho.position.set(this.#size * 1.5, 0, 50);
		this.#world.scene.add(this.#ortho);

		//Create a shader material
		this.#material = new THREE.RawShaderMaterial(
		{
			uniforms: {
				"blur": { type: "f", value:  0.0 },
				"texture": { type: "t", value: this.#texture }
			},
			vertexShader: shaderVert,
			fragmentShader: shaderFrag,
			depthTest: false
		});

		//Create a mesh to film
		const geo = new THREE.PlaneGeometry(this.#size, this.#size, 1, 1);
		const msh = new THREE.Mesh(geo, this.#material);
		msh.position.set(this.#size * 1.5, 0, 0);
		this.#world.scene.add(msh);

		//Create a texture buffer for the material map
		this.#buffer = new THREE.WebGLRenderTarget(this.#size, this.#size, {
			depthBuffer: false,
			stencilBuffer: false
		});
	}

	load (arr)
	{
		this.#count = arr.length;

		const num = Math.ceil(Math.sqrt(this.#count));
		const size = Math.floor(this.#size / num);
		let i, j, newx, newy;

		return new Promise((resolve, reject) => 
		{
			for (let x = 0; x < num; x++)
			{
				for (let y = 0; y < num; y++)
				{
					i = (x * num) + y;

					this.loadImage(arr[i].uri)
					.then(res => res.blob())
					.then(blob => this.createBitmap(blob))
					.then(bmp =>
					{
						j = (x * num) + y;
						newy = arr[j].pos % num;
						newx = Math.floor(arr[j].pos / num);

						this.#context.drawImage(bmp, newx * size, newy * size, size, size);

						if (x === num-1 && y === num-1)
						{
							this.#world.renderer.setRenderTarget(this.#buffer);
							this.#world.renderer.render(this.#world.scene, this.#ortho);
							this.#world.renderer.setRenderTarget(null);
							
							this.#texture.needsUpdate = true;

							this.loadEndImage(resolve, reject);
						}
					});
				}
			}
		});
	}

	async loadImage (img)
	{
		return await fetch(img);
	}

	async createBitmap (blob)
	{
		return await createImageBitmap(blob);
	}

	loadEndImage(resolve, reject)
	{
		this.loadImage(this.#end)
		.then(res => res.blob())
		.then(blob => this.createBitmap(blob))
		.then(bmp =>
		{
			this.#endImage = bmp;

			this.loadReflection(resolve, reject);
		});
	}

	loadReflection (resolve, reject)
	{
		const pmrem = new THREE.PMREMGenerator(this.#world.renderer);

		new TextureLoader().load(this.#map, (hdr) =>
		{
			const map = pmrem.fromCubemap(hdr);

			this.loadModel(map, resolve, reject);
		});
	}

	loadModel (map, resolve, reject)
	{
		const mat = new THREE.MeshBasicMaterial({
			color: 0xffffff,
			map: this.#buffer.texture
		});

		//Load cylinder model
		const loader = new GLTFLoader();
		loader.load(
			this.#uri,
			(gltf) => 
			{
				gltf.scene.traverse(child => {
					if (!child.isMesh) return;
					
					//Create mesh
					const mesh = new THREE.Mesh(child.geometry, mat);
					resolve(mesh);
				});
			},
			undefined,
			(error) =>
			{
				reject(error);
			}
		);
	}

	update (blur)
	{
		if (this.#lastBlur === blur) return;

		this.#step++;

		if (this.#step % this.#stepInterval === 0)
		{
			this.#material.uniforms["blur"].value = blur;

			this.#world.renderer.setRenderTarget(this.#buffer);
			this.#world.renderer.render(this.#world.scene, this.#ortho);
			this.#world.renderer.setRenderTarget(null);

			this.#lastBlur = blur;
		};
	}

	flipEnd ()
	{
		const num = Math.ceil(Math.sqrt(this.#count));
		const size = Math.floor(this.#size / num);

		//Draw background
		this.#context.fillStyle = this.#color;
		this.#context.fillRect(0, 0, size, size);

		//Draw end image
		this.#context.fillStyle = "#ffffff";
		this.#context.drawImage(this.#endImage, 0, 0, size, size);

		this.#world.renderer.setRenderTarget(this.#buffer);
		this.#world.renderer.render(this.#world.scene, this.#ortho);
		this.#world.renderer.setRenderTarget(null);

		this.#texture.needsUpdate = true;
	}
}