import {socket, SocketContext} from './context/socket';
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';

import {AnimatePresence} from 'framer-motion';
import {useEffect} from 'react';
import useWindowSize from './useWindowSize';
import {Lobby} from './pages/Lobby';
import {Game} from './pages/Game';
import './App.scss';
import {useAtom} from 'jotai';
import {hostState, joinedRoomState, lobbyFullState, lobbyIdState, lobbySizeState, playerNumberState, userDisconnectionState} from './state/default_state';

export function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var numbers = '0123456789';
  var charactersLength = characters.length;
  var numberLength = numbers.length;

  for (var i = 0; i < length; i++) {
    if (Math.random() < 50 / 100) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    } else {
      result += numbers.charAt(Math.floor(Math.random() * numberLength));
    }
  }
  return result;
}

function App() {
  const {width, height} = useWindowSize();
  const [, setHasJoinedRoom] = useAtom(joinedRoomState);
  const [, setIsHost] = useAtom(hostState);
  const [, setLobbySize] = useAtom(lobbySizeState);
  const [, setLobbyFull] = useAtom(lobbyFullState);
  const [, setUserDiconnection] = useAtom(userDisconnectionState);
  const [lobbyId] = useAtom(lobbyIdState);
  const [playerNumber, setPlayerNumber] = useAtom(playerNumberState);

  useEffect(() => {
    socket.on('disconnect', function () {
      setUserDiconnection(true);
      setHasJoinedRoom(false);
      setPlayerNumber(undefined);
      setLobbySize(0);
      setLobbyFull(false);
    });

    socket.on('created', function (room, clientId, numClients, playerNo) {
      setHasJoinedRoom(true);
      setIsHost(true);
      socket.emit('request_room_size', lobbyId);
      setPlayerNumber(playerNo);
    });

    socket.on('joined', function (room, clientId, numClients, playerNo) {
      setHasJoinedRoom(true);
      socket.emit('request_room_size', lobbyId);
      setPlayerNumber(playerNo);
    });

    socket.on('full', function (room) {
      setLobbySize(3);
      setLobbyFull(true);
    });

    socket.on('update_lobby_size', function (numClients) {
      setLobbySize(numClients);
    });

    socket.on('update_disconnecting', function () {
      socket.emit('request_room_size', lobbyId);
    });
  }, [lobbyId, playerNumber, setHasJoinedRoom, setIsHost, setLobbyFull, setLobbySize, setPlayerNumber, setUserDiconnection]);

  useEffect(() => {
    window.document.documentElement.style.setProperty('--vh', `${height / 100}px`);
    window.document.documentElement.style.setProperty('--vh100', `${height}px`);
    window.document.documentElement.style.setProperty('--vw100', `${width}px`);
    window.document.documentElement.style.setProperty('--vw', `${width}px`);
  }, [width, height]);

  return (
    <AnimatePresence mode="wait">
      <SocketContext.Provider value={socket}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to={`/i/`} state={{userGenerated: true, setAsHost: true}} />} />

            <Route path="/i/:lobbynumber" element={<Lobby />} />
            <Route path="/i/" element={<Lobby />} />
            <Route path="/i/:lobbynumber/:sharerid" element={<Lobby />} />

            <Route path="/game/i/:lobbynumber/:sharerid" element={<Game />} />
            <Route path="/game/i/:lobbynumber" element={<Game />} />
          </Routes>
        </BrowserRouter>
      </SocketContext.Provider>
    </AnimatePresence>
  );
}

export default App;
