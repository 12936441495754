/**
 * Fruit Machine Reel
 *
 * @author Mike Cobb
 * @copy   Highly Interactive Ltd
 * @date   28/11/2022
**/

import * as THREE from "three";
import gsap from "gsap";

export default class Reel
{
	#count;
	#wide = 6;

	#currentId = 0;
	#endSpeed = 0;
	#velocity = 0;
	#lastRot = 0;
	#target = 0;
	#images;
	#clock;

	#FRICTION = 0.2;
	#SPRING = 0.6;
	#TAU = Math.PI * 2;

	constructor (world, model, images)
	{
		this.#images = images;
		this.#count = images.length;
		const radi = (this.#count * this.#wide) / this.#TAU;
		
		//Place mesh
		this.mesh = model;
		this.mesh.rotateZ(-Math.PI * 0.5);
		world.scene.add(this.mesh);

		world.reelRadius = radi;
		world.reelSize = this.#wide + 0.5;

		//Create a clock
		this.#clock = new THREE.Clock();

		//Set start rotation
		this.mesh.rotation.x = this.#target = this.getAngleFromId(this.#currentId);

		this.goingToSpin = false;
	}

	//Called when reel is being dragged
	drag ()
	{
		let rx = this.mesh.rotation.x;
		this.#velocity += (this.#target - rx) * this.#FRICTION;
		this.#velocity *= this.#SPRING;
		rx += this.#velocity;

		this.mesh.rotation.x = rx;
	}

	//Called when reel is being spun
	spin (speed)
	{
		const delta = this.#clock.getDelta();

		this.mesh.rotation.x += speed * delta;
		this.mesh.rotation.x = this.mod(this.mesh.rotation.x);
	}

	//Called when released too slowly
	snap ()
	{
		if (this.goingToSpin) return;

		this.#target = this.getAngleFromId(this.#currentId);
		this.#velocity = 0;
		
		let angle = {};
		angle.rotation = this.mesh.rotation.x;

		this.tween = gsap.to(angle, 
		{
			duration: 0.6, 
			rotation: this.#target, 
			ease: "back.out",
			onUpdate: () =>
			{
				this.mesh.rotation.x = angle.rotation;
			}
		});
	}

	clearTweens ()
	{
		this.goingToSpin = true;
		if (this.tween) this.tween.kill();
	}

	mod (n, m = this.#TAU)
	{
		return ((n % m) + m) % m;
	}

	slow ()
	{
		const delta = this.#clock.getDelta();

		if (this.#target < delta) return; //stop when wound down

		this.#target -= delta;

		this.mesh.rotation.x += this.#endSpeed * delta;
		this.mesh.rotation.x = this.mod(this.mesh.rotation.x);
	}

	stop (targetId, speed, offset, callback)
	{
		this.#currentId = targetId;
		this.#endSpeed = speed;

		this.mesh.rotation.x = this.getAngleFromId(this.#currentId) - (Math.PI * 0.5);

		let start = this.mesh.rotation.x;
		let end = this.getAngleFromId(this.#currentId);
		let dif = end - start;
		dif = ((dif + this.#TAU * 1e10) % this.#TAU) - ((dif / this.#TAU) | 0) * this.#TAU;
		
		const targ = start + dif;

		let angle = {};
		angle.rotation = start;

		gsap.to(angle, {duration: 1, rotation: targ, delay: offset, ease: "back.out",
			onStart: () =>
			{
				callback();
			},
			onUpdate: () =>
			{
				this.mesh.rotation.x = angle.rotation;
			},
			onComplete: () =>
			{
				this.#target = this.mesh.rotation.x;
				this.#velocity = 0;
				this.goingToSpin = false;
			}
		});
	}

	easeOutBack (x) 
	{
		const c1 = 1.70158;
		const c3 = c1 + 1;

		return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
	}

	addSpeed (speed)
	{
		this.#target += speed;
	}

	getSpeed ()
	{
		const s = this.mesh.rotation.x - this.#lastRot;
		this.#lastRot = this.mesh.rotation.x;
		return s;
	}

	getAngleFromId (id)
	{
		//Get position from id
		const img = this.#images[id];

		if (img === undefined)
		{
			console.error("ERROR: No image with id " + id);
			return Math.PI / this.#count;
		}

		const seg = Math.PI / this.#count;
		let ang = -(2 * seg * img.pos) - seg;

		//Fix for texture issues
		ang += Math.PI * 1.5;

		return this.mod(ang, this.#TAU);
	}
}